import React, { useState, useEffect } from "react";
import axios from "axios";

import PsContext from "./index";
import { getLs, setLs } from "../utils";
import { listSettingsFields } from "../models/fieldSettings";

const PsContextProvider = (props) => {
  const getSettings = () => {
    try {
      let set = getLs("adm_settings");
      if (set && set !== undefined) {
        set = set.trim();
        return JSON.parse(getLs("adm_settings"));
      } else {
        return [];
      }
    } catch (er) {
      console.log(er);
      return [];
    }
  };

  const [backgroundProcess, setBackgroundProcess] = useState(false);

  const [settings, setSettings] = useState(getSettings());

  const loadSettings = () => {
    listSettingsFields().then((res) => {
      setLs("adm_settings", JSON.stringify(res));
      setSettings(res);
    });
  };

  const settingValue = (key) => {
    let m = settings && settings.find((item) => item.field_name == key);
    return m && m.field_name ? m.field_value : false;
  };

  return (
    <PsContext.Provider
      value={{
        setBackgroundProcess: setBackgroundProcess,
        backgroundProcess: backgroundProcess,
        loadSettings: loadSettings,
        settingValue: settingValue,
      }}
    >
      {props.children}
    </PsContext.Provider>
  );
};

export default PsContextProvider;

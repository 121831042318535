import Ract from 'react';
import Axios from 'axios';
import toast from 'react-hot-toast';
import { ServiceUrl } from '../utils/serviceUrl';


export const listSettingsFields = async () => {

	return new Promise((resolve, reject) => {
		Axios.get(ServiceUrl.SETTINGS)
			.then(function ({ data }) {
				resolve(data.data);
			})
			.catch(function (error) {
				//resolve([]);
				return [];
			});
	});
}
